<template>
  <tr>
    <td>{{ orderItem.prescription.code }}</td>
    <td class="text-right">{{ orderItem.quantity }}</td>
    <td class="text-right">{{ orderItem.price }}</td>
    <td class="text-right">
       <input type="number" name="repeats" :value="orderItem.repeats" :readonly="canAdjustRepeats">
    </td>
    <td class="text-right">{{ orderItem.total }}</td>
    <td class="text-right"></td>
  </tr>
</template>

<script>
export default {
  name: "OrderItem",
  props: {
    orderItem: Object,
    order: Object
  },
  data() {
    return {
      repeats: null
    }
  },
  computed: {
    canAdjustRepeats() {
      return this.orderItem.repeats && this.orderItem.repeats > 1;
    }
  }
}
</script>

<style scoped>

</style>