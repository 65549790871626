<template>
  <div class="ps-section__right">
    <form class="ps-form--account-setting" method="get">
      <div class="ps-form__header">
        <h3>Pay for Order</h3>
      </div>
      <div class="ps-form__content">
        <div class="row mt-3 mb-3" v-if="message">
          <div class="col-12">
            <small class="text-danger" v-if="isError">{{ message }}</small>
            <small class="text-success" v-else>{{ message }}</small>
          </div>
        </div>
        <div class="form-group">
          <label>Order Number<sup>*</sup> </label>
          <div class="form-group__content">
            <input class="form-control" type="text" v-model="orderNumber" placeholder="" v-on:keyup="getPrescriptionOrder" />
            <small v-if="errors && errors.order_number" class="phone_error text-danger">
              {{ errors.order_number[0] }}
            </small>
          </div>
        </div>
      </div>
      <span v-if="isSearchingOrder && orderNumber">Loading order...</span>
      <div class="ps-form__content" v-if="order">
        <div class="row">
          <div class="table-responsive mt-2 mb-3 ml-4 mr-3">
            <table class="table ps-table ps-table--vendor">
              <thead>
              <tr>
                <th>Code</th>
                <th class="text-right">Quantity</th>
                <th class="text-right">Price ($)</th>
                <th class="text-right">Repeats</th>
                <th class="text-right">Total ($)</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
                  <OrderItem v-for="orderItem in order.order_items"
                             :orderItem="orderItem"
                             :order="order"
                             :key="orderItem.id"></OrderItem>
              </tbody>
              <tfoot>
              <tr>
                <td colspan="3"><strong>Total</strong></td>
                <td class="text-right"><strong>{{ order.total_amount | toCurrency }}</strong></td>
                <td></td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-xs-6 margintop">
          </div>
          <div class="col-6 col-xs-6 text-right pull-right invoice-total">
            <p><strong>Total</strong>: {{ order.total_amount | toCurrency }} </p>
          </div>
        </div>
      </div>
      <div class="ps-form__content" v-else-if="orderNumber && (orderNumber.length > 3 && !isSearchingOrder)">
        <div class="row">
          <div class="text-danger mt-2 mb-3 ml-4">Order {{ orderNumber }} not found, please ensure you entered correct order number</div>
        </div>
      </div>
      <div class="ps-form__content text-right" v-if="order">
        <div class="form-group">
          <router-link
              v-if="order.status.name === 'pending'"
              class="ps-btn" :to="{name: 'payment', params: {id: order.id}}">Continue to pay</router-link>
        </div>
      </div>
    </form>

  </div>

</template>

<script>
import axios from "axios";
import OrderItem from "./OrderItem";
export default {
  name: "Create",
  components: {OrderItem},
  data() {
    return {
      total: 0,
      lines: [],
      isRemoving: false,
      isLoading: false,
      isSearchingOrder: false,
      isError: false,
      message: null,
      orderNumber: "",
      order: null,
      errors: null
    }
  },
  methods: {
    getPrescriptionOrder() {
      if (this.orderNumber.length > 3) {
        this.isSearchingOrder = true;
        axios.get('orders/prescriptions/search-order?order_number=' + this.orderNumber).then((response) => {
          this.order = response.data.data;
          this.isSearchingOrder = false;
        })
      }
    },

    onSubmit() {
      this.isLoading = true;
      axios.post('orders/prescriptions/create', {
        total_amount: this.total,
        order_items: this.lines
      }).then((response) => {
        this.isLoading = false;
        if (response.status === 200 || response.status === 201) {
          this.message = 'Prescription order successfully created'
          this.$router.push({name: 'merchant_prescription_orders'});
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.message = "Prescription order could not be created. Please enter all required fields.";
          this.errors = error.response.data.errors
        } else {
          this.message = error.backendErrors[0]
        }
        this.isError = true
        this.isLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>